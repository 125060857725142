<script lang="ts">
    import { getStoresContext } from "~/stores";
    import type { StoresKey } from "~/stores";
    
    export let request_store_key: StoresKey;
    export let footerLeftFontColor: string;
    export let footerRightFontColor: string;
    export let footerLogoColor: string;
    export let additionalLinks: any[] = [];
    export let mallName: string;
    export let mallAddressLink: any;
    export let facebookIcon: { img: any, link: any };
    export let instagramIcon: { img: any, link: any };
    export let tiktokIcon: { img: any, link: any };
    export let linkedinIcon: { img: any, link: any };

    const stores_context = getStoresContext(request_store_key);
    const { locale, T, nav_menus } = stores_context;
    
    // Direct reactive subscription to nav_menus - matches Header.svelte approach
    $: primary = $nav_menus?.find?.(nav => nav.navigationType === "Primary");
    $: primaryNavigation = primary?.navigationMenuItems || [];
    $: reverse_router = stores_context.reverse_router;

    function getNavUrl(navItem: any): string {
        if (navItem.link) {
            return $reverse_router.routePage(navItem.link);
        }
        return navItem.url || '';
    }

    function getNavLabel(navItem: any): string {
        return stores_context.i18nOf(navItem.name_i18n) || navItem.name || '';
    }

    function isDirectImage(img: any): boolean {
        return img && typeof img === 'object' && 'src' in img;
    }

    function getImageUrl(icon: { img: any, link: any }): string {
        if (!isDirectImage(icon.img)) {
            throw new Error("Invalid image data");
        }
        return icon.img.src;
    }

    function getImageAlt(icon: { img: any, link: any }): string {
        if (!isDirectImage(icon.img)) {
            throw new Error("Invalid image data");
        }
        if (typeof icon.img.alt !== 'string') {
            throw new Error("Missing alt text");
        }
        return icon.img.alt;
    }

    function getLinkUrl(icon: { img: any, link: any }): string {
        if (icon.link && typeof icon.link.href === 'string' && icon.link.href !== '') {
            return icon.link.href;
        }
        return '';
    }

    function getLinkTarget(icon: { img: any, link: any }): string {
        if (icon.link && icon.link.target) {
            return icon.link.target;
        }
        return '_blank';
    }

    function isValidSocialIcon(icon: { img: any, link: any }): boolean {
        return isDirectImage(icon.img);
    }

    function getNavTarget(navItem: any): string {
        // Use _self for internal links, _blank for external
        return navItem.link ? "_self" : "_blank";
    }

    $: footerLeftFontColor = footerLeftFontColor || "#000000";
    $: footerRightFontColor = footerRightFontColor || "#000000";
    $: footerLogoColor = footerLogoColor || "#E3FF64";
</script>

<footer class="footer">
    <div class="footer-left" style:color={footerLeftFontColor}>
        <div class="main-links">
            {#if primaryNavigation && primaryNavigation.length > 0}
                {#each primaryNavigation.slice(0, 9) as navItem}
                    <a 
                        href={getNavUrl(navItem)} 
                        rel="noopener noreferrer" 
                        target={getNavTarget(navItem)}
                        class="main-link"
                    >
                        {getNavLabel(navItem)}
                    </a>
                {/each}
            {/if}
        </div>

        <div class="secondary-links">
            {#if primaryNavigation && primaryNavigation.length > 9}
                <div class="invisible-divider"></div>
                {#each primaryNavigation.slice(9) as navItem}
                    <a 
                        href={getNavUrl(navItem)} 
                        rel="noopener noreferrer" 
                        target={getNavTarget(navItem)}
                        class="regular-link"
                    >
                        {getNavLabel(navItem)}
                    </a>
                {/each}
            {/if}
            
            {#if additionalLinks && additionalLinks.length > 0}
                {#each additionalLinks as link}
                    <!-- Links without text will not be rendered here because they're filtered out in the Astro component -->
                    <a 
                        href={link.href} 
                        rel="noopener noreferrer" 
                        target={link.target || "_blank"}
                        class="regular-link additional-link"
                    >
                        {link.text}
                    </a>
                {/each}
            {/if}
        </div>
    </div>

    <div class="footer-right" style:color={footerRightFontColor}>
        <div class="socials">
            {#if isValidSocialIcon(facebookIcon)}
                <a href={getLinkUrl(facebookIcon)} target={getLinkTarget(facebookIcon)} rel="noopener noreferrer">
                    <img src={getImageUrl(facebookIcon)} alt={getImageAlt(facebookIcon)} />
                </a>
            {/if}
            {#if isValidSocialIcon(instagramIcon)}
                <a href={getLinkUrl(instagramIcon)} target={getLinkTarget(instagramIcon)} rel="noopener noreferrer">
                    <img src={getImageUrl(instagramIcon)} alt={getImageAlt(instagramIcon)} />
                </a>
            {/if}
            {#if isValidSocialIcon(tiktokIcon)}
                <a href={getLinkUrl(tiktokIcon)} target={getLinkTarget(tiktokIcon)} rel="noopener noreferrer">
                    <img src={getImageUrl(tiktokIcon)} alt={getImageAlt(tiktokIcon)} />
                </a>
            {/if}
            {#if isValidSocialIcon(linkedinIcon)}
                <a href={getLinkUrl(linkedinIcon)} target={getLinkTarget(linkedinIcon)} rel="noopener noreferrer">
                    <img src={getImageUrl(linkedinIcon)} alt={getImageAlt(linkedinIcon)} />
                </a>
            {/if}
        </div>
        <div class="info">
            <h2>{mallName}</h2>
            <a href={mallAddressLink.href} rel="noopener noreferrer" target={mallAddressLink.target || "_blank"}>
                {mallAddressLink.text}
            </a>
        </div>
    </div>
</footer>

<div class="footer-logo">
    <svg width="100%" height="auto" viewBox="0 0 1920 428" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1920 0.854492H1822.48V428.173H1920V0.854492ZM1663.89 179.221C1654.6 189.091 1643.29 194.009 1629.95 194.009H1599.06V82.3508H1629.95C1643.29 82.3508 1654.57 86.5853 1663.89 95.0215C1673.17 103.458 1677.81 117.366 1677.81 136.682C1677.81 155.997 1673.17 169.385 1663.89 179.221ZM1756.86 70.8853C1748.99 53.5893 1738.69 39.8112 1725.97 29.5509C1713.25 19.2906 1699.1 11.9617 1683.57 7.53186C1668.01 3.102 1652.58 0.887096 1637.21 0.887096H1503.92V428.205H1599.02V272.476H1640.81C1656.56 272.476 1672.09 269.773 1687.46 264.333C1702.79 258.894 1716.52 250.555 1728.65 239.285C1740.78 228.015 1750.46 213.748 1757.71 196.42C1764.97 179.124 1768.63 158.603 1768.63 134.858C1768.63 109.516 1764.71 88.1813 1756.83 70.8853M1345.3 179.221C1336.01 189.091 1324.7 194.009 1311.36 194.009H1280.47V82.3508H1311.36C1324.7 82.3508 1335.98 86.5853 1345.3 95.0215C1354.58 103.458 1359.22 117.366 1359.22 136.682C1359.22 155.997 1354.58 169.385 1345.3 179.221ZM1438.31 70.8853C1430.43 53.5893 1420.13 39.8112 1407.41 29.5509C1394.7 19.2906 1380.54 11.9617 1365.01 7.53186C1349.45 3.102 1334.02 0.887096 1318.69 0.887096H1185.4V428.205H1280.5V272.476H1322.28C1338.04 272.476 1353.57 269.773 1368.93 264.333C1384.27 258.894 1398 250.555 1410.13 239.285C1422.26 228.015 1431.93 213.748 1439.19 196.42C1446.45 179.124 1450.11 158.603 1450.11 134.858C1450.11 109.516 1446.19 88.1813 1438.31 70.8853ZM1102.75 428.173V0.854492H973.126L905.878 276.059H903.458L834.412 0.854492H712.043V428.173H799.888L796.848 134.858H798.679L868.346 428.173H936.805L1008.89 134.858H1011.31L1007.68 428.173H1102.78H1102.75ZM533.64 275.473H460.344L496.698 93.8164H499.117L533.64 275.473ZM664.476 428.173L560.906 0.854492H439.159L334.968 428.173H431.901L449.457 350.325H545.769L563.325 428.173H664.476ZM302.896 428.173L185.988 196.42L296.227 0.854492H187.786L99.3526 178.896H97.5218V0.854492H0V428.173H97.5218V229.611H99.3526L190.826 428.173H302.896Z" fill="{footerLogoColor}"></path>
    </svg>
</div>

<style lang="scss">
    .footer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        padding: 0 0 12rem 0;
        
        @media screen and (max-width: 575px) {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 0 0 3rem 0;
        }
    }

    .footer-left {
        display: flex;
        flex-direction: column;
        gap: 0;
        
        @media screen and (max-width: 575px) {
            order: 3;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
        }
    }

    .footer-right {
        display: grid;
        grid-template-columns: 1fr;
        align-items: flex-start;
        justify-content: end;
        gap: 10px;
        
        @media screen and (max-width: 575px) {
            order: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }

    .footer-left a {
        font-size: 24px;
        font-weight: 600;
        color: inherit;
        text-decoration: none;
        font-family: var(--font-bold);
        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
        @media screen and (max-width: 575px) {
            font-size: 16px;
            margin-bottom: 8px;
        }
    }

    .footer-left .regular-link {
        font-weight: 400;
        font-family: var(--font-main);
    }

    .footer-left .additional-link {
        color: inherit;
    }

    .footer-left a:hover {
        text-decoration: none;
    }

    .footer-right .socials {
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: end;
        gap: 3rem;
        
        @media screen and (max-width: 575px) {
            justify-content: center;
            margin-bottom: 2rem;
        }
    }

    .footer-right img {
        height: 40px;
        @media screen and (max-width: 768px) {
            height: 20px;
        }
    }

    .invisible-divider {
        height: 2rem;
        
        @media screen and (max-width: 575px) {
            display: none;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: end;
        height: 100%;
        color: inherit;
        text-align: right;
        font-family: var(--font-bold);
        
        @media screen and (max-width: 575px) {
            order: 2;
            align-items: center;
            text-align: center;
            margin-bottom: 2rem;
        }
    }

    .info h2 {
        font-size: 24px;
        font-weight: 600;
        color: inherit;
        font-family: inherit;
        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
        @media screen and (max-width: 575px) {
            font-size: 18px;
            margin-bottom: 8px;
        }
    }

    .info a {
        font-size: 24px;
        color: inherit;
        font-family: inherit;
        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
        @media screen and (max-width: 575px) {
            font-size: 16px;
        }
    }

    .footer-logo {
        @media screen and (min-width: 991px) {
            margin: 0 -60px;
        }
    }

    .main-links, .secondary-links {
        display: flex;
        flex-direction: column;
        
        @media screen and (max-width: 575px) {
            display: flex;
            flex-direction: column;
        }
    }
    
    .main-links {
        @media screen and (max-width: 575px) {
            align-items: flex-start;
        }
    }
    
    .secondary-links {
        @media screen and (max-width: 575px) {
            align-items: flex-start;
        }
    }
    
    .footer-left .main-link {
        font-weight: 600;
        font-family: var(--font-bold);
    }
</style>